<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <price-x-logo/>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('titles.welcome_to_pricex') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('messages.please_signin_to_your_account') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
            ref="loginForm"
            #default="{invalid}"
        >
          <b-form
              ref="loginForm"
              class="auth-login-form mt-2"
              @submit.prevent="submitLogin"
          >
            <div class="text-danger mb-1" v-if="loginError">{{ loginError }}</div>
            <!-- email -->
            <b-form-group
                label-for="email"
                :label="$t('label.email')"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
              >
                <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                    autofocus
                    tabindex="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{ $t('label.password') }}</label>
                <b-link :to="{name:'auth.forgot.password'}">
                  <small>{{ $t('buttons.forgot_password') }}</small>
                </b-link>
              </div>
              <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      :placeholder="$t('label.password')"
                      tabindex="2"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-overlay :show="submitLoading" rounded
                       spinner-small
                       spinner-variant="primary">
              <b-button
                  variant="primary"
                  type="submit"
                  block
                  tabindex="4"
                  :disabled="invalid || submitLoading"
              >
                {{ $t('buttons.sign_in') }}
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>{{ $t('questions.new_on_our_platform') }}</span>
          <b-link :to="{name:'auth.register'}">
            <span> {{ $t('buttons.create_an_account') }}</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            {{ $t('messages.or') }}
          </div>
        </div>

        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
              @click="handleFacebookLogin"
              variant="facebook"
          >
            <feather-icon icon="FacebookIcon"/>
          </b-button>
          <b-button
              @click="handleGoogleLogin"
              variant="google"
          >

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" width="14" height="14" fill="#ffffff"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
          </b-button>

          <div style="width: 35px;">
            <vue-apple-login
                mode="logo-only"
                type="sign in"
                color="black"
                :border="true"
                :radius="15"
                width="100%"
                height="100%"
                logoSize="small"
                :logoPosition="0"
                :labelPosition="0"
                className="vue-apple-login"
                :onSuccess="callSuccess"
                :onFailure="callFail"
            >
            </vue-apple-login>
          </div>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue'
import PriceXLogo from '@core/layouts/components/Logo.vue'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import useJwt from "@/auth/jwt/useJwt";
import {EventBus} from "@/plugins/event-bus";
import {mapActions} from "vuex";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    PriceXLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  metaInfo() {
    return {
      title: this.$t('page_titles.login')
    }
  },
  data() {
    return {
      userEmail: '',
      password: '',
      loginError: null,
      // validation rules
      required,
      email,
      submitLoading: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('user', ['fetch']),
    submitLogin() {
      this.submitLoading = true
      this.$refs.loginForm.validate().then(success => {
        if (!success) {
          return;
        }
        useJwt.login({
          username: this.userEmail,
          password: this.password,
          grant_type: 'password',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          scope: '*',
        }).then(response => {
          useJwt.setToken(response.data.access_token)
          useJwt.setRefreshToken(response.data.refresh_token)
          this.fetch().then(userResponse => {
            this.$ability.update(userResponse.data.ability)
            this.$nextTick(() => {
              EventBus.$emit('login')
              this.$router.push('/')
            })
          })
        }).catch(error => {
          if (error.response.status === 400) {
            this.$refs.loginForm.setErrors({Password: error.response.data.message})
          } else {
            this.$refs.loginForm.setErrors(error.response.data.errors)
          }
        }).finally(() => {
          this.submitLoading = false
        })
      })
    },
    handleFacebookLogin() {
      this.$http.get('/login/facebook').then(response => {
        window.location.href = response.data
      })
    },
    handleGoogleLogin() {
      this.$http.get('/login/google').then(response => {
        window.location.href = response.data
      })
    },
    handleAppleLogin() {
      this.$http.get('/login/apple').then(response => {
        window.location.href = response.data
      })
    },
    callSuccess(data){
      this.$router.push({name: 'auth.social', params: {driver: 'apple'}, query: {code: data.authorization.code}})
    },
    callFail(e){
      console.error(e)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
